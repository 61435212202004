import React, { useState } from "react";
import { loginService } from "../../services/login";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const handlesubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      password: password,
    };
    console.log(payload);
    await loginService(navigate, payload);
  };
  return (
    <div className="login-staff">
      <h1>Login</h1>
      <form
        onSubmit={(e) => {
          handlesubmit(e);
        }}
        action=""
      >
        <input
          type="email"
          value={email}
          onChange={(e) => {
            setemail(e.target.value);
          }}
          placeholder="email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setpassword(e.target.value);
          }}
          placeholder="password"
        />
        <button type="submit">submit</button>
      </form>
    </div>
  );
};

export default Login;
