import apiCall from "./apiCall";
import { errorToast } from "../utils/toast";
function handleSuccess(data, navigate) {
  localStorage.setItem("staffData", JSON.stringify(data.staff));
  navigate("/");
  return data;
}

export const loginService = async (navigate, formData) => {
  try {
    const data = await apiCall("/loginStaff", "POST", formData);
    return handleSuccess(data, navigate);
  } catch (error) {
    errorToast(error.message);
  }
};