import React from "react";
import Logo from "../assets/recalllogo.svg";
import UserIcon from "../assets/user.svg";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="navbar max-container">
      <div
        onClick={() => {
          navigate("/");
        }}
        className="nav-logo"
      >
        <img src={Logo} alt="" />
        Recall Staff Portal
      </div>
      <div
        onClick={() => {
          navigate("/profile");
        }}
        className="nav-user"
      >
        <img src={UserIcon} alt="" />
      </div>
    </div>
  );
};

export default Navbar;
