import React, { useEffect, useState } from "react";
import Welcome from "../../assets/welcome.png";
import Arrow from "../../assets/arrow.svg";
import { useNavigate } from "react-router-dom";
import { loginService } from "../../services/login";
import { getAllPickupAdmin } from "../../services/pickup";
const Index = () => {
  const navigate = useNavigate();
  const staffData = JSON.parse(localStorage.getItem("staffData"));

  console.log(staffData);

  useEffect(() => {
    const fetchData = async () => {
      const res = await loginService(navigate, {
        email: staffData?.email,
        password: "demo",
      });
      console.log(res);
    };
    fetchData();
  }, [navigate, staffData?.email]);

  //notification count for my pickups
  const [pendingPickups, setPendingPickups] = useState([]);
  const [pendingPickups2, setPendingPickups2] = useState([]);

  useEffect(() => {
    const fetchPickups = async () => {
      const res = await getAllPickupAdmin();
      const matchingPickups = [];
      for (const pickup of res) {
        const pickupAreaId = pickup?.user.area;
        staffData.area_allotted.forEach((area) => {
          const allocatedAreaId = area[0];
          if (pickupAreaId === allocatedAreaId) {
            matchingPickups.push(pickup);
          }
        });
      }
      //  if pickup status is to be collected then only show in my pickups

      const filteredPickups = matchingPickups.filter((pickup) => {
        return pickup.pickup_status === "toBeCollected";
      });
      setPendingPickups(filteredPickups);

      const filteredPickups2 = matchingPickups.filter((pickup) => {
        return pickup.pickup_status === "confirmed" && !pickup.staff_assigned;
      });
      setPendingPickups2(filteredPickups2);
    };

    fetchPickups();
  }, []);

  const operations = [
    {
      name: "New Pickups",
      path: "/pickups",
      type: "ctype", // Specify the type as a single string
      count: pendingPickups2.length,
    },
    {
      name: "My Pickups",
      path: "/mypickups",
      type: "ctype",
      count: pendingPickups.length,
    },
    {
      name: "Pickup History",
      path: "/pickuphistory",
      type: "ctype",
    },
    {
      name: "To be sorted",
      path: "/tobesorted",
      type: "stype",
    },
    {
      name: "Scan Bag",
      path: "/scanner/stype",
      type: "stype",
    },
  ];
  console.log(pendingPickups2.length);
  console.log(pendingPickups.length);

  // Filter the operations based on the staffData.type (ctype or stype)
  const filteredOperations = operations?.filter((operation) =>
    staffData?.type?.includes(operation.type)
  );

  return (
    <div className="home | max-container">
      <div className="home-welcome-card">
        <div className="home-welcome-card-img">
          <img src={Welcome} alt="" />
        </div>
        <div className="welcome-text">
          <h1>
            Hello <br />
            {staffData?.name} 👋
          </h1>
          <p>
            This is your personalised <br /> dashboard for pickups
          </p>
        </div>
      </div>
      <div className="staff-operations">
        <h1>Manage Pickups 🚚</h1>
        <div className="staff-operations-list">
          {filteredOperations.map((operation) => (
            <div
              key={operation.path}
              onClick={() => {
                navigate(operation.path);
              }}
              className="staff-operations-list-item"
            >
              {operation.count && operation.count > 0 && (
                <div className="count">{operation.count}</div>
              )}
              <h2>{operation.name}</h2>
              <div className="arrow">
                <img src={Arrow} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
