import React, { useState, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import { updatePickup } from "../services/updateStatus";
const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};
const modal = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "10px",
    opacity: 1,
    transition: { delay: 0.5 },
  },
};

const UpdateStatus = ({ showModal, setShowModal, senddata, status }) => {
  const updateStatusfunction = async () => {
    const payload = {
      pickup_status: status,
      private_key: senddata?.user.private_key,
      pickup_id: senddata?.id,
    };
    const res = await updatePickup(payload);
    console.log(res);
    setShowModal((prev) => !prev);
  };

  return (
    <>
      {showModal && (
        <AnimatePresence>
          <motion.div
            className="backdrop"
            variants={backdrop}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <motion.div
              variants={modal}
              className="modal-container | user-info-modal"
            >
              <div className="user-data">User Information</div>
              <div className="user-data-list">
                <div className="user-data-list-item">
                  <p>Name :</p>
                  <p>{senddata?.user.name}</p>
                </div>
                <div className="user-data-list-item">
                  <p>Address :</p>
                  <p>{senddata?.user.address}</p>
                </div>
                <div className="user-data-list-item">
                  <p>Status : </p>
                  <p>{senddata?.pickup_status}</p>
                </div>
                <div className="user-data-list-item">
                  <p>Phone:</p>
                  <p>{senddata?.user.phone}</p>
                </div>
                <div className="user-data-list-item">
                  <p>Email:</p>
                  <p>{senddata?.user.email}</p>
                </div>
                <div className="user-data-list-item">
                  <p>Date : </p>
                  <p>{senddata?.date}</p>
                </div>
                <div className="user-data-list-item">
                  <p>Type : </p>
                  <p>{senddata?.user.type_of}</p>
                </div>
                <div className="user-data-list-item">
                  <p>Buisness Name: </p>
                  <p>{senddata?.user.business_name}</p>
                </div>
              </div>
              <button
                onClick={() => {
                  updateStatusfunction();
                }}
              >
                Update Status
              </button>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default UpdateStatus;
