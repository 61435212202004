import React, { useState, useEffect } from "react";
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import { getAllPickupAdmin } from "../../services/pickup"; 
import { useNavigate } from "react-router-dom";

const ScannerStype = () => {
  const navigate = useNavigate();
  const [startScanner, setStartScanner] = useState(false);
  const [showButton, setshowButton] = useState(false);
  const [pendingPickups, setPendingPickups] = useState([]);
  const [, setMatchedPickup] = useState(null);
  useEffect(() => {
    const fetchPickups = async () => {
      const res = await getAllPickupAdmin();

      setPendingPickups(res);
    };

    fetchPickups();
  }, []);
  console.log(pendingPickups);
  const findPickup = async (serial_id) => {
    const id = String(serial_id);
    console.log(id);
    const foundPickup = pendingPickups.find(
      (pickup) => pickup.serial_id === serial_id
    );

    if (foundPickup) {
      console.log("Found Pickup:", foundPickup);
      navigate("/update-status", { state: foundPickup });
      setMatchedPickup(foundPickup);
    } else {
      console.log("Pickup not found.");
    }
  };

  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: { width: 200, height: 200 },
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      },
      false
    );
    const onScanFailure = () => {};
    const onScanSuccess = async (decodedText) => {
       await html5QrcodeScanner.clear();
      console.log(decodedText);
      const scannedSerialId = decodedText;
      console.log(scannedSerialId);
      findPickup(scannedSerialId);

      setshowButton(true);
    };
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);
  }, [startScanner, navigate]);
  return (
    <div className="scanner | max-container">
      <div className="user-data">Scan Bag</div>
      <div className="qr-scanner">
        <div id="reader" className="faq-card"></div>
      </div>
      {showButton && (
        <button onClick={() => setStartScanner((p) => !p)}>Scan Again</button>
      )}
    </div>
  );
};

export default ScannerStype;
