import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllPickupAdmin } from "../../services/pickup";

const TobeSorted = () => {
  const navigate = useNavigate();
  const [pendingPickups, setPendingPickups] = useState([]);
  useEffect(() => {
    const fetchPickups = async () => {
      const res = await getAllPickupAdmin();

      setPendingPickups(res);
    };

    fetchPickups();
  }, []);
  return (
    <div className="operation-page | max-container"> 
      <div className="staff-operations">
        <h1>Pcikups To be Sorted 🚚</h1>
      </div>
      <div className="table">
        <div className="table-inner">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Status</th>
                <th>Date</th>
                <th> User</th>
              </tr>
            </thead>
            <tbody>
              {pendingPickups.map((pickup) => {
                console.log(pickup);
                return (
                  <>
                    {pickup.pickup_status === "collected" ? (
                      <tr
                        onClick={() => {
                          navigate("/update-status", { state: pickup });
                        }}
                        key={pickup.id}
                      >
                        <td>{pickup.user.name}</td>
                        <td>{pickup.user.address}</td>
                        <td
                          className={
                            pickup.pickup_status === "completed"
                              ? "green"
                              : "red"
                          }
                        >
                          {pickup.pickup_status}
                        </td>
                        <td>{pickup.date}</td>
                        <td>{pickup.user.type_of}</td>
                      </tr>
                    ) : null}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TobeSorted;
