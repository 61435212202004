import { successToast } from "../utils/toast";

export const updatePickup = async (formData,navigate) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/updatePickup`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      throw new Error("Getting Admin Stats failed");
    }

    const data = await response.json();
    if(data.message === "Pickup Updated"){
      successToast("Pickup Updated");
      navigate("/");
      
    }
    return data;
  } catch (error) {
    console.log(error);
    // throw new Error(`Getting Admin Stats failed: ${error.message}`);
  }
};
