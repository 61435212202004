import apiCall from "./apiCall";
import { errorToast } from "../utils/toast";
import { loginService } from "./login";
 
export const changePassword = async (navigate, formData) => {
  try {
    const finalData = {
        staff_id: formData.staff_id,
      password: formData.password,
    };
    const data = await apiCall("/updateStaff", "PUT", finalData);
    const payload = {
      email: formData.email,
      password: formData.password,
    };
    console.log(payload);
    loginService(navigate, payload);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};