import React, { useState, useEffect } from "react";
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import { useLocation,useNavigate } from "react-router-dom";
import { updatePickup } from "../../services/updateStatus";
const Index = () => {
  const navigate = useNavigate();
  const [startScanner, setStartScanner] = useState(false);
  const [showButton, setshowButton] = useState(false);
  //take state form location
  const location = useLocation();
  const senddata = location.state;
  const sendSerialId = async (serialId) => {
    const payload = {
      serial_id: serialId, 
      pickup_status: "completed",
      private_key: senddata?.user.private_key,
      pickup_id: senddata?.id,
    };
    console.log(payload);
    const res = await updatePickup(payload,navigate);
    console.log(res);
  }
  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: { width: 200, height: 200 },
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      },
      false
    );
    const onScanFailure = () => {};
    const onScanSuccess = async (decodedText) => {
      const res = await html5QrcodeScanner.clear();
      // await verifyScannedCode(decodedText);
      //   console.log(res);
       sendSerialId(decodedText);
      console.log(decodedText);
      setshowButton(true);
    };
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);
  }, [startScanner]);
  return (
    <div className="scanner | max-container">
      <div className="user-data">User Information</div>
      <div className="user-data-list">
        <div className="inner-flex">
          <div className="user-data-list-item">
            <p>Name :</p>
            <p>{senddata?.user.name}</p>
          </div>
          <div className="user-data-list-item">
            <p>Address :</p>
            <p>{senddata?.user.address}</p>
          </div>
          <div className="user-data-list-item">
            <p>Status : </p>
            <p>{senddata?.pickup_status}</p>
          </div>
          <div className="user-data-list-item">
            <p>Phone:</p>
            <p>{senddata?.user.phone}</p>
          </div>
          <div className="user-data-list-item">
            <p>Email:</p>
            <p>{senddata?.user.email}</p>
          </div>
        </div>
        <div className="inner-flex">
          <div className="user-data-list-item">
            <p>Date : </p>
            <p>{senddata?.date}</p>
          </div>
          <div className="user-data-list-item">
            <p>Type : </p>
            <p>{senddata?.user.type_of}</p>
          </div>
          <div className="user-data-list-item">
            <p>Buisness Name: </p>
            <p>{senddata?.user.business_name}</p>
          </div>
        </div>
      </div>
      {showButton && (
        <button onClick={() => setStartScanner((p) => !p)}>Scan Again</button>
      )}

      <div className="qr-scanner">
        <div id="reader" className="faq-card"></div>
      </div>
    </div>
  );
};

export default Index;
