export const updateStats = async (formData) => {
    // const admin_token = JSON.parse(localStorage.getItem("recallAdmin"));
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateStats`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
  
      if (!response.ok) {
        throw new Error("Getting Admin Stats failed");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      // throw new Error(`Getting Admin Stats failed: ${error.message}`);
    }
  };
  