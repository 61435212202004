import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Scanner from "../pages/Scanner";
import { Navigate } from "react-router-dom";
import Login from "../pages/Authentication/Login";
import Navbar from "../components/Navbar";
import UpdateStatus from "../pages/Operations/UpdateStatus";
import PendingStatus from "../pages/Operations/PendingStatus";
import MyPickups from "../pages/Operations/MyPickups";
import PickupHistory from "../pages/Operations/PickupHistory";
import TobeSorted from "../pages/Operations/TobeSorted";
import ScannerStype from "../pages/Scanner/ScannerStype";
import ChangePassword from "../pages/Authentication/ChangePassword";
const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    element: <Home />,
    private: true,
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    element: <Login />,
    private: false,
  },
  {
    path: "/update-status",
    exact: true,
    name: "Update Status",
    element: <UpdateStatus />,
    private: true,
  },
  {
    path: "/mypickups",
    exact: true,
    name: "My Pickups",
    element: <MyPickups />,
    private: true,
  },
  {
    path: "/pickuphistory",
    exact: true,
    name: "Pickup History",
    element: <PickupHistory />,
    private: true,
  },
  {
    path: "/scanner",
    exact: true,
    name: "Scanner",
    element: <Scanner />,
    private: true,
  },
  {
    path: "/scanner/stype",
    exact: true,
    name: "Scanner",
    element: <ScannerStype />,
    private: true,
  },
  {
    path: "/tobesorted",
    exact: true,
    name: "To Be Sorted ",
    element: <TobeSorted />,
    private: true,
  },
  {
    path: "/pickups",
    exact: true,
    name: "Pending Pickup",
    element: <PendingStatus />,
    private: true,
  },
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    element: <Profile />,
    private: true,
  },
  {
    path: "/change-password",
    exact: true,
    name: "Change Password",
    element: <ChangePassword />,
    private: true,
  },
];

export default function Navigation() {
  

  
  const staffData = localStorage.getItem("staffData");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the current route requires login (private route)
    const privateRoute = routes.find(
      (route) => route.path === location.pathname && route.private
    );

    // If it's a private route and staffData is not available, redirect to the login page
    if (privateRoute && !staffData) {
      navigate("/login");
    }

    // If staffData is available and the user is on the login page, redirect to the home page
    if (staffData && location.pathname === "/login") {
      navigate("/");
    }
  }, [location.pathname, staffData, navigate]);

  return (
    <>
      <Navbar />
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.private && !staffData ? (
                <Navigate to="/login" />
              ) : (
                route.element
              )
            }
          />
        ))}
      </Routes>
    </>
  );
}
