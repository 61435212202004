export const getAllPickupAdmin = async () => {
  const payload = {
    token:
      "FXBSA37CPNRGMJY1BM65V7MM3BBCEPFEQ3DWHXS6LR2MVR17NW20Y6X3PWV0SS7IFL0GH8YCI5J8EUI6WUULBABGYAJCLIPDS6NF",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/getAllPickups`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      throw new Error("Getting All User failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Getting All User failed: ${error.message}`);
  }
};
