import React, { useState, useEffect } from "react";
import { getAllPickupAdmin } from "../../services/pickup";

const PickupHistory = () => {
  const staffData = JSON.parse(localStorage.getItem("staffData"));
  const [pendingPickups, setPendingPickups] = useState([]);
  useEffect(() => {
    const fetchPickups = async () => {
      const res = await getAllPickupAdmin();
      const matchingPickups = [];
      for (const pickup of res) {
        const pickupAreaId = pickup?.user.area;
        staffData.area_allotted.forEach((area) => {
          const allocatedAreaId = area[0];
          if (pickupAreaId === allocatedAreaId) {
            // Add the matching pickup to the temporary array
            matchingPickups.push(pickup);
          }
        });
      }
      setPendingPickups(matchingPickups);
    };

    fetchPickups();
  }, []);
  return (
    <div className="operation-page | max-container">
      <div className="staff-operations">
        <h1>Pending pickups 🚚</h1>
      </div>
      <div className="table">
        <div className="table-inner">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Status</th>
                <th>Date</th>
                <th>Type of User</th>
              </tr>
            </thead>
            <tbody>
              {pendingPickups.map((pickup) => {
                console.log(pickup.staff_assigned);
                return (
                  <>
                    <tr onClick={() => {}} key={pickup.id}>
                      <td>
                        <div>{pickup.user.name}</div>
                      </td>

                      <td>
                        <div>{pickup.user.address}</div>
                      </td>
                      <td
                        className={
                          pickup.pickup_status === "completed" ? "green" : "red"
                        }
                      >
                        <div>{pickup.pickup_status.substring(0, 4)}..</div>
                      </td>
                      <td>
                        <div>{pickup.date}</div>
                      </td>
                      <td>
                        <div>{pickup.user.type_of.substring(0, 1)}</div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PickupHistory;
