import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllPickupAdmin } from "../../services/pickup"; 

const MyPickups = () => {
  const navigate = useNavigate();

  const staffData = JSON.parse(localStorage.getItem("staffData"));
  const [pendingPickups, setPendingPickups] = useState([]);

  useEffect(() => {
    const fetchPickups = async () => {
      const res = await getAllPickupAdmin();
      const matchingPickups = [];
      for (const pickup of res) {
        const pickupAreaId = pickup?.user.area;
        staffData.area_allotted.forEach((area) => {
          const allocatedAreaId = area[0];
          if (pickupAreaId === allocatedAreaId) {
            matchingPickups.push(pickup);
          }
        });
      }
      setPendingPickups(matchingPickups);
    };

    fetchPickups();
  }, []);

  return (
    <div className="operation-page | max-container">
      <div className="staff-operations">
        <h1>My pickups 🚚</h1>
      </div>
      <div className="table">
        <div className="table-inner">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>

                <th>Status</th>
                <th>Date</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {pendingPickups.map((pickup) => { 
                if(pickup.pickup_status === "toBeCollected"){

                  console.log(pickup);
                }
                return (
                  <>
                    {pickup.pickup_status === "toBeCollected" ? (
                      <tr
                        onClick={() => {
                          navigate("/scanner", { state: pickup });
                        }}
                        key={pickup.id}
                      >
                        <td>
                          <div>{pickup.user.name}</div>
                        </td>

                        <td>
                          <div>{pickup.user.address}</div>
                        </td>
                        <td
                          className={
                            pickup.pickup_status === "completed"
                              ? "green"
                              : "red"
                          }
                        >
                          <div>
                            {pickup.pickup_status === "toBeCollected"
                              ? "TBC"
                              : pickup.pickup_status}{" "}
                          </div>
                        </td>
                        <td>
                          <div>{pickup.date}</div>
                        </td>
                        <td>
                          <div>{pickup.user.type_of.substring(0, 1)}</div>
                        </td>
                      </tr>
                    ) : null}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MyPickups;
