import React,{useState} from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { updatePickup } from "../../services/updateStatus";
import { updateStats } from "../../services/updateStats";

const UpdateStatus = () => {
  //recieve state from navigate
  const location = useLocation();
  const navigate = useNavigate(); 
  const [size, setsize] = useState();
  const pickup = location.state;
  console.log(pickup);
  const updateStatus = async () => {
    const payload = {
      pickup_status: "completed",
      private_key: pickup.user.private_key,
      pickup_id: pickup.id,
    };
    const payload2 = {
      private_key: pickup.user.private_key,
      cans_recycled: parseInt(size),
      total_points: parseInt(size),
    };
    const res = await updatePickup(payload, navigate);
    console.log(res);

    const res2 = await updateStats(payload2);
    console.log(res2);
  };
  return (
    <div className="operation-page | max-container">
      <div className="staff-operations">
        <h1>Update Status of {pickup?.user.name}'s pickup 🔖</h1>
      </div>
      <div className="update-status-fields">
        <div className="input-field">
          <p>Enter the Quantity of pickup</p>
          <input
            type="number"
            onChange={(e) => {
              setsize(e.target.value);
            }}
            value={size}
            placeholder="Pickup quantity"
          />
        </div>
        <div className="update-status-btns">
          <button className="secondary">Go Back</button>
          <button
            onClick={() => {
              updateStatus();
            }}
          >
            Update Status{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateStatus;
