import React, { useState } from "react"; 
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../services/changePassword";
const Login = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const handlesubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      password: password,
      staff_id: JSON.parse(localStorage.getItem("staffData"))?.id,
    }; 
    await changePassword(navigate, payload);
  };
  return (
    <div className="login-staff">
      <h1>Change Password</h1>
      <form
        onSubmit={(e) => {
          handlesubmit(e);
        }}
        action=""
      >
        <input
          type="email"
          value={email}
          onChange={(e) => {
            setemail(e.target.value);
          }}
          placeholder="email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setpassword(e.target.value);
          }}
          placeholder="new password"
        />
        <button type="submit">submit</button>
      </form>
    </div>
  );
};

export default Login;
