import React from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../../assets/arrow.svg";
const Index = () => {
  const navigate = useNavigate();
  const staffData = JSON.parse(localStorage.getItem("staffData"));
  console.log(staffData);
  return (
    <div className="home | max-container">
      <div className="profile-welcome-card">
        <div className="welcome-text">
          <h1>
            Hello <br />
            {staffData?.name} 👋
          </h1>
          <p>
            This is your personalised <br /> dashboard for pickups
          </p>
        </div>
      </div>
      <div className="staff-operations">
        <h1>Profile settings 🙎🏻‍♂️</h1>
        <div className="staff-operations-list">
          <div
            onClick={() => {
              localStorage.removeItem("staffData");
              navigate("/login");
            }}
            className="staff-operations-list-item"
          >
            <h2>Logout</h2>
            <div className="arrow">
              <img src={Arrow} alt="" />
            </div>
          </div>
          <div
            onClick={() => { 
              navigate("/change-password");
            }}
            className="staff-operations-list-item"
          >
            <h2>Change Password</h2>
            <div className="arrow">
              <img src={Arrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
