
import React from "react"; 
import { BrowserRouter } from "react-router-dom";
import Navigation from "./routes/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
 
function App() {
    return (
        <BrowserRouter> 
         <ToastContainer />
            <Navigation /> 
        </BrowserRouter>
    );
}

export default App;
